<template>
  <g-card page-title="Reseller Invoice List" page-icon="mdi-view-list" :back-button="backButton">
    <g-list-page
        :columns="columns"
        :headers="headers"
        :show-default-action-buttons="false"
        :action-buttons="actionButton"
        :show-total="true"
        stateendpoint="invoice.invoices"
        sort="invoiceNo"
        :descending="true"
        :show-selected="true"
        :payment-status="currentPaging.paymentStatus"
        @loadData="loadData"
        @selectedItemHandler="selectedItemHandler"
        @editItemNewPage="editInvoice"
        @deleteItem="deleteItem"
        @destroyItem="destroyItem"
        @restoreItem="restoreItem"
        @resetItem="resetItem"
        @invoiceMailDetailsHandler="invoiceMailDetailsHandler"
        @invoiceDetailsHandler="invoiceDetailsHandler"
        @invoiceLinkHandler="invoiceLinkHandler"
        @resendPaymentMailHandler="resendPaymentMailHandler"
        @reminderPaymentMailHandler="reminderPaymentMailHandler"
        @afterReminderPaymentMailHandler="afterReminderPaymentMailHandler"
        @makePaymentHandler="makePaymentHandler"
        @refundInvoiceHandler="refundInvoiceHandler"
        @republishedMailHandler="republishedMailHandler"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>

      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(i.e: invoice no, email, links)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12" v-if="selectedInvoices.length > 0">
            <chip-button
                color="teal darken-4"
                :x-small="false"
                icon="mdi-link-variant"
                text="Copy All Links"
                @onClickHandler="copyMultipleLinkHandler()"
            />
          </v-col>
        </v-row>
        <v-row style="margin: 0 5px">
          <g-p-auto-complete
              :dense="true"
              :outlined="true"
              :status=true
              :validation="false"
              sort-by="type"
              apiPath="mail-template/get-payment-template"
              search-label="Send Mail: Select a Template"
              @clearSearchResult="onChangePaymentEmailHandler"
              @selectSearchResult="onChangePaymentEmailHandler"
              class="ma-2 ml-2"
              style="max-width: 280px"
          />
          <g-date-selector
              class="ma-2 ml-6"
              @onChangeDateHandler="calenderHandler"
          />
          <v-select
              dense
              outlined
              class="ma-2 ml-6"
              :items="payStatusItems"
              v-model="currentPaging.paymentStatus"
              label="Select a Payment Status"
              style="max-width: 250px"
              clearable
              @change="loadData({isPagingReset: true})"
          />
          <g-p-auto-complete
              :dense="true"
              :outlined="true"
              :status=false
              :validation="false"
              apiPath="get-admins"
              search-label="Select a User"
              @clearSearchResult="userHandler"
              @selectSearchResult="userHandler"
              class="ma-2 ml-6"
              style="max-width: 220px"
          />
          <g-p-auto-complete
              :dense="true"
              :outlined="true"
              :status=false
              :validation="false"
              apiPath="methods"
              search-label="Select Payment Method"
              @clearSearchResult="methodHandler"
              @selectSearchResult="methodHandler"
              class="ma-2 ml-6"
              style="max-width: 220px"
          />
          <v-select
              dense
              outlined
              class="ma-2 ml-6 mr-2"
              :items="paymentEmails"
              v-model="currentPaging.mailType"
              label="Select Email Type"
              style="max-width: 220px"
              clearable
              @change="loadData({isPagingReset: true})"
          />
        </v-row>
      </template>
    </g-list-page>
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span v-html="confirmTxt"></span>
      </div>
    </confirm-popup>
    <link-popup
        :show="showPopup"
        :item="editedItem"
        @closePopupHandler="closePopupHandler"
    />
    <manual-payment-popup
        :show="showManualPayment"
        :item="editedItem"
        @saveItemHandler="saveItemHandler"
        @closePopupHandler="closePopupHandler"
    />
  </g-card>

</template>

<script>
import PageTitle from "../../components/PageTitle";
import GCard from "../../components/GCard";
import GListPage from "../../components/list/GListPage";
import PageOverlay from "../../components/PageOverlay";
import ListMixins from "../../components/mixins/list/List";
import GPSearch from "../../components/GPSearch";
import GDateSelector from "../../components/calendar/GDateSelector";
import GPAutoComplete from "../../components/GPAutoComplete";
import ConfirmPopup from "../../components/popups/ConfirmPopup";
import LinkPopup from "../../components/popups/LinkPopup";
import ManualPaymentPopup from "../../components/popups/ManualPaymentPopup";
import moment from "moment";
import ChipButton from "../../components/buttons/ChipButton";
import AddButton from "../../components/buttons/AddButton";

export default {
  name: "Invoices",
  metaInfo: {
    title: 'shantoitfirm.com',
    titleTemplate: 'Admin Dashboard - Invoices | %s',
    meta: [
      {name: 'robots', content: 'noindex'},
      {name: 'googlebot', content: 'noindex'}
    ]
  },
  components: {
    AddButton,
    ChipButton,
    ManualPaymentPopup,
    LinkPopup,
    ConfirmPopup,
    GPAutoComplete,
    GDateSelector,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
    PageTitle
  },
  mixins: [ListMixins],
  created() {
    this.currentPaging.mailType = this.$route.query.mailType ? this.$route.query.mailType : ""
    this.currentPaging.startDate = moment().startOf('year').format('YYYY-MM-DD');
    this.currentPaging.endDate = moment().endOf('year').format('YYYY-MM-DD');
  },
  data() {
    return {
      currentTemplate: "",
      selectedInvoices: [],
      showManualPayment: false,
      headers: [
        {
          text: 'Method',
          value: 'attachment'
        },
        {
          text: 'Invoice No',
          value: 'invoiceNo'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Total',
          value: 'totalAmount'
        },
        {
          text: 'Status',
          value: 'paymentStatus'
        },
        {
          text: 'Is Prepaid?',
          value: 'isPrepayment'
        },
        {
          text: 'Created Date',
          value: 'createdAt'
        },
        {
          text: 'Updated Date',
          value: 'updatedAt'
        },
        {
          text: 'Paid Date',
          value: 'paymentDate'
        },
        {
          text: 'User',
          value: 'user.name'
        },
        {
          text: 'Removal Mail',
          value: 'removalMail'
        },
        {
          text: 'After Removal Mail',
          value: 'afterRemovalMail'
        },
        {
          text: 'Link',
          value: 'refLink',
          align: 'right',
          sortable: false
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      actions: {
        load: 'invoice/load',
        update: 'invoice/makeManualPayment',
        restore: 'invoice/restore',
        remove: 'invoice/remove',
        destroy: 'invoice/destroy',
        get: 'mailTemplate/getByType',
        reset: 'invoice/reset',
        sendMail: 'mailTemplate/sendInvoiceEmail',
        paymentMailHandler: 'invoice/resendMailHandler',
        reminderMail: 'invoice/sendReminderMail',
        afterReminderMail: 'invoice/sendAfterReminderMail',
        refund: 'invoice/refundInvoice',
        republishedEmail: 'invoice/republishedEmail'
      },
      actionButton: [
        {
          category: 'edit',
          icon: 'mdi-pencil',
          color: '',
          text: 'Edit',
          clickHandler: 'editItemNewPage'
        },
        {
          category: 'delete',
          icon: 'mdi-delete',
          color: 'error',
          text: 'Delete',
          clickHandler: 'deleteItem'
        },
        {
          category: 'deletePermanent',
          icon: 'mdi-delete-alert',
          color: 'error',
          text: 'Destroy Permanent',
          clickHandler: 'destroyItem'
        },
        {
          category: 'restore',
          icon: 'mdi-backup-restore',
          color: '',
          text: 'Restore',
          clickHandler: 'restoreItem'
        },
        {
          category: 'reset',
          icon: 'mdi-reload',
          color: '',
          text: 'Reset Invoice',
          clickHandler: 'resetItem'
        },
        {
          category: 'link',
          icon: 'mdi-eye',
          color: '',
          text: 'View Live Link',
          clickHandler: 'invoiceLinkHandler'
        },
        {
          category: 'mailDetails',
          icon: 'mdi-email-mark-as-unread',
          color: '',
          text: 'View Mail Details',
          clickHandler: 'invoiceMailDetailsHandler'
        },
        {
          category: 'details',
          icon: 'mdi-details',
          color: '',
          text: 'View Details',
          clickHandler: 'invoiceDetailsHandler'
        },
        {
          category: 'resendMail',
          icon: 'mdi-mail',
          color: '',
          text: 'Resend Payment Email',
          clickHandler: 'resendPaymentMailHandler'
        },
        {
          category: 'reminder',
          icon: 'mdi-email-alert',
          color: '',
          text: 'Post Removal Payment Email',
          clickHandler: 'reminderPaymentMailHandler'
        },
        {
          category: 'afterReminder',
          icon: 'mdi-delete-empty',
          color: '',
          text: 'After Post Removal Payment Email',
          clickHandler: 'afterReminderPaymentMailHandler'
        },
        {
          category: 'republishEmail',
          icon: 'mdi-email',
          color: 'green',
          text: 'Send Article Republished Email',
          clickHandler: 'republishedMailHandler'
        },
        {
          category: 'manualCredit',
          icon: 'mdi-dolly',
          color: '',
          text: 'Make a Manual Payment',
          clickHandler: 'makePaymentHandler'
        },
        {
          category: 'refund',
          icon: 'mdi-credit-card-refund',
          color: '',
          text: 'Refund Invoice',
          clickHandler: 'refundInvoiceHandler'
        }
      ],
      payStatusItems: [
        {text: 'Unpaid', value: 100},
        {text: 'Paid', value: 200},
        {text: 'Prepaid', value: 700},
        {text: 'Deleted', value: 500}
      ],
      paymentEmails: [
        {text: 'Payment Mail', value: 'paymentMail'},
        {text: 'Update Mail', value: 'updateMail'},
        {text: 'Removal Mail', value: 'removalMail'},
        {text: 'After Removal Mail', value: 'afterRemovalMail'},
      ],
      backButton: {
        text:"Create a new invoice",
        icon: "mdi-keyboard-backspace",
        name: "Invoice",
        path: "/invoice",
      },
      columns: [
        {
          name: "totalAmount",
          callback: "getMoneySign"
        },
        {
          name: "createdAt",
          callback: "getDate"
        },
        {
          name: "paymentDate",
          callback: "getDate"
        },
        {
          name: "updatedAt",
          callback: "getDate"
        },
      ],
    }
  },
  methods: {
    userHandler(selection){
      if(selection) {
        this.currentPaging.user = selection.id;
      } else {
        this.currentPaging.user = "";
      }

      this.loadData({isPagingReset: true});
    },
    methodHandler(selection){
      if(selection) {
        this.currentPaging.method = selection.id;
      } else {
        this.currentPaging.method = "";
      }

      this.loadData({isPagingReset: true});
    },
    selectedItemHandler(items) {
      this.selectedInvoices = items;
    },
    async onChangePaymentEmailHandler(item, confirm = false) {
      if(!item){
        return;
      }
      if(this.selectedInvoices.length === 0){
        this.setSnackBar({text: 'No invoice are selected, Please select a invoice', type: 'info'});
        return;
      }
      this.confirmEvent = this["onChangePaymentEmailHandler"];
      this.confirmTxt = `Are you sure to send email template: <strong>${item.name}</strong> to <strong>${this.selectedInvoices.length}</strong> selected invoice?`;

      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;

      let selectedItems = this.selectedInvoices.map((item) => item.id);

      await this.handleItem(this.actions.sendMail, {selectedItems, templateType: this.currentSelectedItem.type});
    },
    editInvoice(item) {
      this.$router.push({name: "EditInvoice", path: `/edit-invoice/${item.id}`, params: {id:item.id}, query: this.$route.query})
    },
    async resetItem(item, confirm = false){
      this.confirmEvent = this["resetItem"];
      this.confirmTxt = `Are you sure to reset this invoice <strong>${item.invoiceNo}</strong>?`;

      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      await this.handleItem(this.actions.reset, item);
    },
    invoiceMailDetailsHandler(item) {
      this.$router.push({name: "InvoiceMailDetails", path: `/invoice-mail-details/${item.id}`, params: {id:item.id}})
    },
    invoiceDetailsHandler(item) {
      this.$router.push({name: "InvoiceDetails", path: `/invoice-details/${item.id}`, params: { id: item.id }})
    },
    invoiceLinkHandler(item) {
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.showPopup = true;
    },
    async resendPaymentMailHandler(item, confirm = false) {
      this.confirmEvent = this["resendPaymentMailHandler"];
      this.confirmTxt = `Are you sure to send payment mail to this invoice <strong>${item.invoiceNo}</strong>?`;

      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      await this.handleItem(this.actions.paymentMailHandler, item);
    },
    async reminderPaymentMailHandler(item, confirm = false) {
      this.confirmEvent = this["reminderPaymentMailHandler"];
      this.confirmTxt = `Are you sure to send reminder payment mail to this invoice <strong>${item.invoiceNo}</strong>?`;

      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      await this.handleItem(this.actions.reminderMail, item);
    },
    async afterReminderPaymentMailHandler(item, confirm = false) {
      this.confirmEvent = this["afterReminderPaymentMailHandler"];
      this.confirmTxt = `Are you sure to send after reminder payment mail to this invoice <strong>${item.invoiceNo}</strong>?`;

      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      await this.handleItem(this.actions.afterReminderMail, item);
    },
    makePaymentHandler(item) {
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.showManualPayment = true;
    },
    async refundInvoiceHandler(item, confirm = false){
      this.confirmEvent = this["refundInvoiceHandler"];
      this.confirmTxt = `Are you sure to refund this invoice <strong>${item.invoiceNo}</strong> and total amount <strong>$${item.totalAmount}</strong>?`;

      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      await this.handleItem(this.actions.refund, {id: item.id});
    },
    async republishedMailHandler(item, confirm = false) {
      this.confirmEvent = this["republishedMailHandler"];
      this.confirmTxt = `Are you sure to send republish email to this invoice  No.: <strong>#${item.invoiceNo}</strong>?`;

      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      await this.handleItem(this.actions.republishedEmail, {id: item.id});
    },
    copyMultipleLinkHandler() {
      if(this.selectedInvoices.length === 0) {
        this.setSnackBar({text: 'No invoice are selected, Please select a invoice', type: 'info'});
        return;
      }

      let links = this.selectedInvoices.map((item) => {
        const api = process.env.VUE_APP_BASE_URL;
        return `${api}/direct-payment?refno=${item.refcode}`;
      });
      let me = this;
      this.$copyText(links.join("\r\n")).then(function (e) {
        me.setSnackBar({text: 'Clipboard Copied!', type: 'info'})
      }, function (e) {
        me.setSnackBar({text: 'Can not Copied!', type: 'error'})
      })
    }
  }
}
</script>